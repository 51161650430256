
// 汎用関数一覧
$.validator.fn = {
  errorPlacement(error, element) {
    error.addClass('font-italic text-danger invalid-feedback')
    // エラー一旦すべて削除（一つだけ表示）
    element.closest('.form-group,.input-group').find('.error').remove()
    element.closest('.form-group,.input-group').append(error)
  },

  highlight(element) {
    $(element).addClass('is-invalid')
    $(element).closest('.form-group,.input-group').find('input[type=radio]').addClass('is-invalid')
  },

  unhighlight(element) {
    // 必須グループのエラー表示の場合
    if ($(element).data('require_group_error')) {
      return false
    }

    if ($(element).val() || $(element).prop('checked')) {
      $(element).removeClass('is-invalid')
      $(element).closest('.form-group,.input-group').find('input[type=radio]').removeClass('is-invalid')
    } else if (!$(element).val() || !$(element).prop('checked')) {
      $(element).removeClass('is-invalid')
      $(element).closest('.form-group,.input-group').find('input[type=radio]').removeClass('is-invalid')
    }
  }
}

// 金額 バリデーション(マイナスなし版の仕様)
$.validator.addMethod('currency1', (value, element) => {
  // 全角数値 => 半角数値変換
  value = value.replace(/[０-９]/g, s => {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
  })
  // 全角ハイフン => 半角ハイフン変換
  value = value.replace(/[‐‑–—―−ーｰ-]/g, () => { // [―‐−ー－]
    return '-'
  })

  // 英数字とカンマ以外削除
  value = value.replace(/\D/g, '')

  // 先頭の余計な0を削除
  value = value.replace(/(?!^-)0*(.)/, '$1')

  // -0の場合0に変換
  value = value.replace(/^-0$/, '0')

  // 一旦出力
  $(element).val(value)

  // 数字に3桁ごとにカンマを自動入力
  value = String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

  $(element).val(value)

  // エラー判定
  if ($.validator.prototype.optional(element) || /^(\d{1,3}(,\d{3})*|(\d+))$/.test(value)) {
    return true
  }
})

// 金額 バリデーション(マイナスあり版の仕様)
$.validator.addMethod('currency2', (value, element) => {
  // 全角数値 => 半角数値変換
  value = value.replace(/[０-９]/g, s => {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
  })
  // 全角ハイフン => 半角ハイフン変換
  value = value.replace(/[‐‑–—―−ーｰ-]/g, () => { // [―‐−ー－]
    return '-'
  })

  // 英数字とハイフン(カンマも)以外削除
  value = value.replace(/[^\d-]/g, '')

  // 先頭以外にある"-"は削除
  value = value.replace(/(?!^-)-/g, '')

  // 先頭の余計な0を削除
  value = value.replace(/(?!^-)0*(.)/, '$1')

  // -0の場合0に変換
  value = value.replace(/^-0$/, '0')

  // 一旦出力
  $(element).val(value)

  // 数字に3桁ごとにカンマを自動入力
  value = String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

  if ($.validator.prototype.optional(element) || /^-*(\d{1,3}(,\d{3})*|(\d+))$/.test(value)) {
    $(element).val(value)
    return true
  }
})

$.validator.addMethod('hankakueisuji', (value, element) => {
  return $.validator.prototype.optional(element) || /^[\dA-Za-z]+$/.test(value)
})
$.validator.addMethod('hankakueisujikigou', (value, element) => {
  return $.validator.prototype.optional(element) || /^[\d!-/:-~¥]+$/.test(value)
})

// 固定番号 バリデーション
$.validator.addMethod('tel1', (value, element, options) => {
  // 全角ハイフン = 半角ハイフン変換
  value = value.replace(/[‐‑–—―−ーｰ-]/g, () => { // [―‐−ー－]
    return '-'
  })

  // 全角数値 => 半角数値変換
  value = value.replace(/[０-９]/g, s => {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
  })

  // 残りの全角削除
  if (options.unneceZenkakuDelete) {
    value = value.replace(/[^\u0001-\u007E\u00A1-\u00DF]/g, () => {
      return ''
    })
  }

  $(element).val(value)

  // カッコが入っている場合
  if (/[()]/.test(value)) {
    return $.validator.prototype.optional(element) || false
  }

  // 正規表現でパターンチェック
  if (/(^(0(?!(990|800|570|180|170|120|0|20|50))\d{1,4}-\d{1,4}-\d{4})$|^050-\d{4}-\d{4})|(^0[1346-9]0-\d{4}-\d{4})$/.test(value)) {
    return $.validator.prototype.optional(element) || true
  }

  // マッチしない場合エラーなし
  return $.validator.prototype.optional(element) || false
})
// 固定番号 全角削除 バリデーション
$.validator.addMethod('tel1ZenkakuD', (value, element) => {
  // 全角ハイフン = 半角ハイフン変換
  value = value.replace(/[‐‑–—―−ーｰ-]/g, () => { // [―‐−ー－]
    return '-'
  })

  // 全角数値 => 半角数値変換
  value = value.replace(/[０-９]/g, s => {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
  })

  // 残りの全角削除
  // if (options.unneceZenkakuDelete) {
  value = value.replace(/[^\u0001-\u007E\u00A1-\u00DF]/g, () => {
    return ''
  })
  // }

  $(element).val(value)

  // カッコが入っている場合
  if (/[()]/.test(value)) {
    return $.validator.prototype.optional(element) || false
  }

  // 正規表現でパターンチェック
  if (/(^(0(?!(990|800|570|180|170|120|0|20|50))\d{1,4}-\d{1,4}-\d{4})$|^050-\d{4}-\d{4})|(^0[1346-9]0-\d{4}-\d{4})$/.test(value)) {
    return $.validator.prototype.optional(element) || true
  }

  // マッチしない場合エラーなし
  return $.validator.prototype.optional(element) || false
})
// 携帯番号 バリデーション
$.validator.addMethod('tel2', (value, element, options) => {
  // 全角ハイフン = 半角ハイフン変換
  value = value.replace(/[‐‑–—―−ーｰ-]/g, () => { // [―‐−ー－]
    return '-'
  })

  // 全角数値 => 半角数値変換
  value = value.replace(/[０-９]/g, s => {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
  })

  // 残りの全角削除
  if (options.unneceZenkakuDelete) {
    value = value.replace(/[^\u0001-\u007E\u00A1-\u00DF]/g, () => {
      return ''
    })
  }

  $(element).val(value)

  // カッコが入っている場合
  if (/[()]/.test(value)) {
    return $.validator.prototype.optional(element) || false
  }

  // 正規表現でパターンチェック
  if (/^0[1346-9]0-\d{4}-\d{4}$/.test(value)) {
    return $.validator.prototype.optional(element) || true
  }

  // マッチしない場合エラーなし
  return $.validator.prototype.optional(element) || false
})
// FAX バリデーション
$.validator.addMethod('tel3', (value, element, options) => {
  // 全角ハイフン = 半角ハイフン変換
  value = value.replace(/[‐‑–—―−ーｰ-]/g, () => { // [―‐−ー－]
    return '-'
  })

  // 全角数値 => 半角数値変換
  value = value.replace(/[０-９]/g, s => {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
  })

  // 残りの全角削除
  if (options.unneceZenkakuDelete) {
    value = value.replace(/[^\u0001-\u007E\u00A1-\u00DF]/g, () => {
      return ''
    })
  }

  $(element).val(value)

  // カッコが入っている場合
  if (/[()]/.test(value)) {
    return $.validator.prototype.optional(element) || false
  }

  // 正規表現でパターンチェック
  if (/^0\d{1,5}(-\d{1,4})?-\d{4}$/.test(value)) {
    return $.validator.prototype.optional(element) || true
  }

  // マッチしない場合エラーなし
  return $.validator.prototype.optional(element) || false
});

($ => {
  $.fn.validatorMethods = function (settings) {
    const myValidator = '#' + this.attr('id')
    this.settingsDef = {
      errorElement: 'div',
      onkeyup: false,
      errorPlacement: $.validator.fn.errorPlacement,
      highlight: $.validator.fn.highlight,
      unhighlight: $.validator.fn.unhighlight
    }
    this.settings = $.extend(this.settingsDef, settings)
    this.vInit = function () {
      // 最初の一回だけ実行 (validate仕様?)
      $(myValidator).one('blur.validate', 'input', function () {
        $(this).valid()
      })
      // ペースト時バリデーション
      $(myValidator).find('input').on('paste', e => {
        const { target } = e
        setTimeout(() => {
          $(target).valid()
        }, 1)
      })
    }

    this.vStart = function () {
      $(myValidator).validate(this.settings)
    }

    this.vRun = function () {
      this.vInit()
      this.vStart()
    }

    return this
  }
})(jQuery)
